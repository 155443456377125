<template>
    <v-container class="pa-0">
        <v-subheader class="subheading">{{ $t('termsOfUse.storage-rule') }}</v-subheader>
        <v-card flat subheader>
            <v-card-text class="pt-0 pb-0" :class="backColor">
                <ul v-for="(item, index) in keepingRules" :key="index">
                    <li>{{ item }}</li>
                </ul>
            </v-card-text>
        </v-card>
        <v-subheader class="subheading">{{ $t('termsOfUse.other-precaution') }}</v-subheader>
        <v-card flat subheader>
            <v-card-text class="pt-0 pb-0" :class="backColor">
                <ul v-for="(item, index) in warnings" :key="index">
                    <li>{{ item }}</li>
                </ul>
                <br>
                <p>{{ $t('termsOfUse.please-contact') }}</p>
                <p style="text-align:right">
                    {{ $t('termsOfUse.wan-chou-tong') }}<br>
                    {{ $t('termsOfUse.service-hotline') }} : <a href="tel:+886 2 27253996">02-2725-3996</a> {{ $t('termsOfUse.hours') }}
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'terms-of-use',
    props: {
        backColor: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            keepingRules: [
                i18n.t('termsOfUse.temporary-only-once'),
                i18n.t('termsOfUse.month-unlimited'),
                i18n.t('termsOfUse.temporary-or-month-exceed'),
                i18n.t('termsOfUse.qr-code-expire'),
                i18n.t('termsOfUse.item-more-30-day')
            ],
            warnings: [
                i18n.t('termsOfUse.storage-only'),
                i18n.t('termsOfUse.prohibit-place'),
                i18n.t('termsOfUse.user-damage'),
                i18n.t('termsOfUse.reserve-right'),
                i18n.t('termsOfUse.limit-liability')
            ]
        }
    }
}
</script>